import React from "react";
import "./Form.scss";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../utils/function";

export default function SuccessPage(props) {
  const location = useLocation();
  return (
    <div className="success-page-container">
      <div className="row-1" style={{ color: props.color }}>
        Felicitari,
      </div>
      <div className="row-1" style={{ color: props.color }}>
        Datele au fost inregistrate!
      </div>
      <div className="row-2" style={{ color: props.color }}>
        Vei primi o copie a formularului pe adresa ta de e-mail
      </div>
      <div className="download-button-row">
        <a
          href={baseUrl(`club/download_tax_form?id=${location.state.download_id}&unique_form_id=${location.state.unique_form_id}`)}
        >
          <button
            className="submit-button"
            style={{
              backgroundColor: props.buttonColor,
              color: props.buttonColorText,
            }}
          >
            DESCARCA FORMULARUL
          </button>
        </a>
      </div>
    </div>
  );
}
