import React from "react";
import "./Input.scss";
// import { css } from "@emotion/css";

const Input = ({ value = "", handleChange, label, ...otherInputProps }) => (
  <div className={"group " + otherInputProps.className}>
    <input
      className="form-input"
      // className={
      //   otherInputProps.id_club === "1"
      //     ? "form-input-poli"
      //     : otherInputProps.id_club === "4"
      //       ? "form-input-city"
      //       : "form-input-didi"
      // }
      // className={"form-input " + css`
      //                   color: ${otherInputProps.main_color};
      //                   &:focus: {
      //                     background-color: ${otherInputProps.main_color};
      //                   } 
      //                   `}
      value={value}
      onChange={handleChange}
      name={otherInputProps.name}
    />
    {label && (
      <label
        className={
          value.length
            ? "shrink form-input-label"
            : "form-input-label"
        }
        style={{ color: otherInputProps.labelColor }}
      >
        {label}
      </label>
    )}
  </div>
);

export default Input;
