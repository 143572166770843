export const baseUrl = (relativePath = "") => {
    let baseurl = "https://api.thefootballbrain.app/";
    const { NODE_ENV } = process.env;

    if (NODE_ENV === "development") {
        baseurl = "http://localhost/api-tfb/";
    }
    return baseurl + relativePath;
}

export const prepareForComparation = (text = "") => {
    return text
        .toLocaleLowerCase() // Convert to lowercase
        .normalize("NFD") // Normalize to decompose characters with diacritics
        .replace(/[\u0300-\u036f]/g, "") // Remove diacritical marks
        .trim(); // Remove leading and trailing spaces
};